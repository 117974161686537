import Vue from 'vue';
import VueRouter from 'vue-router';
// import Layout from "../views/Layout.vue"
import Layout from '@/views/LayoutNew.vue';

Vue.use(VueRouter); // 模块注册

const routes = [
  {
    path: '/',
    name: 'Layout',
    component: Layout,
    redirect: '/home',
    children: [
      // 首页
      {
        path: '/home',
        name: 'home',
        component: () => import('@/views/Home')
      },
      // 解决方案
      {
        path: '/solution',
        name: 'solution',
        component: () => import('@/views/solution')
      },
      // 典型案例
      {
        path: '/cases',
        name: 'cases',
        component: () => import('@/views/cases.vue')
      },
      // 新闻中心
      {
        path: '/news',
        name: 'news',
        component: () => import('@/views/News')
      },
      // 新闻详情
      {
        path: '/newsDetail',
        name: 'newsDetail',
        component: () => import('@/views/newsDetail')
      },
      // 职等你来
      {
        path: '/Position',
        name: 'Position',
        component: () => import('../views/Position')
      },
      // 关于我们
      {
        path: '/About',
        name: 'About',
        component: () => import('../views/About')
      }
    ]
  },
  // {
  //   path: '/layout',
  //   name: 'layout',
  //   component: () => import('../views/Layout.vue')
  // },
  // {
  //   path: '/oilPlatform',
  //   name: 'oilPlatform',
  //   component: () => import('../views/solutions/oilPlatform.vue')
  // },
  {
    path: '/404',
    name: '404',
    component: () => import('../views/404')
  }
];
const router = new VueRouter({
  // mode: "hash", // 路由模式，另一种模式是 hash
  // base: process.env.BASE_URL,
  routes
});

export default router;
