import Vue from 'vue'
import App from './App.vue'
import router from "./router"
import store from './store';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/css/common.css'
import './assets/css/base.css'
import BaiduMap from 'vue-baidu-map'
Vue.config.productionTip = false

Vue.use(ElementUI);
Vue.use(BaiduMap, {
  ak: 'vmdLPaCOSq35LNEVLf5Zm0eWr7lfq2Gg'
});

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
