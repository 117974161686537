import Vue from 'vue';
import Vuex from 'vuex';
import { getBaseInfo, getLinksList, getBannerList } from '@/api/home'
import { getHonorInfo, getCoopInfo, getWelfareInfo, getContactInfo, getWorthValue,  } from '@/api/about'

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    imgUrlConfig: window.imgUrlConfig,
    baseInfo: null,
    honorInfo: null,
    coopInfo: null,
    welfareInfo: null,
    contactInfo: null,
    linkList: null,
    worthValue: null,
    homeBanner: null,
    otherBanner: null,
    homeBusiness: null
  },
  getters: {
    baseInfo(state){
      if(state.baseInfo){
        return state.baseInfo
      } else {
        return {}
      }
    },
    honorInfo(state){
      if(state.honorInfo){
        return state.honorInfo
      } else {
        return {}
      }
    },
    coopInfo(state){
      if(state.coopInfo){
        return state.coopInfo
      } else {
        return {}
      }
    },
    welfareInfo(state){
      if(state.welfareInfo){
        return state.welfareInfo
      } else {
        return {}
      }
    },
    contactInfo(state){
      if(state.contactInfo){
        return state.contactInfo
      } else {
        return {}
      }
    },
    linkList(state){
      if(state.linkList){
        return state.linkList
      } else {
        return []
      }
    },
    worthValue(state){
      if(state.worthValue){
        return state.worthValue
      } else {
        return {}
      }
    },
    homeBanner(state){
      if(state.homeBanner){
        return state.homeBanner
      } else {
        return []
      }
    },
    otherBanner(state){
      if(state.otherBanner){
        return state.otherBanner
      } else {
        return []
      }
    },
    homeBusiness(state){
      if(state.homeBusiness){
        return state.homeBusiness
      } else {
        return []
      }
    },
  },
  mutations: {
    setBaseInfo(state, data){
      state.baseInfo = data
    },
    setHonorInfo(state, data){
      state.honorInfo = data
    },
    setCoopInfo(state, data){
      state.coopInfo = data
    },
    setWelfareInfo(state, data){
      state.welfareInfo = data
    },
    setContactInfo(state, data){
      state.contactInfo = data
    },
    setLinkList(state, data){
      state.linkList = data
    },
    setWorthValue(state, data){
      state.worthValue = data
    },
    setBanner(state, data){
      state.homeBanner = data.homeBannerList
      state.otherBanner = data.otherBannerList
      state.homeBusiness = data.businessBannerList
    }
  },
  actions: {
    getBaseInfo({ commit, state }){
      if(!state.baseInfo){
        getBaseInfo()
        .then(res => {
          if(res.code === 200){
            commit('setBaseInfo', res.data[0])
          }
        })
      }
    },
    getHonorInfo({ commit, state }){
      if(!state.honorInfo){
        getHonorInfo()
        .then(res => {
          if(res.code === 200){
            commit('setHonorInfo', res.data)
          }
        })
      }
    },
    getCoopInfo({ commit, state }, callback){
      console.log(123,state.coopInfo, callback)
      if(!state.coopInfo){
        getCoopInfo()
        .then(res => {
          if(res.code === 200){
            commit('setCoopInfo', res.data[0])
            callback && callback()
          }
        })
      } else {
        callback && callback()
      }
    },
    getWelfareInfo({ commit, state }){
      if(!state.welfareInfo){
        getWelfareInfo()
        .then(res => {
          if(res.code === 200){
            commit('setWelfareInfo', res.data)
          }
        })
      }
    },
    getContactInfo({ commit, state }){
      if(!state.contactInfo){
        getContactInfo()
        .then(res => {
          if(res.code === 200){
            commit('setContactInfo', res.data[0])
          }
        })
      }
    },
    getLinksList({ commit, state }){
      if(!state.linkList){
        getLinksList()
        .then(res => {
          if(res.code === 200){
            commit('setLinkList', res.data)
          }
        })
      }
    },
    getWorthValue({ commit, state }){
      if(!state.worthValue){
        getWorthValue()
        .then(res => {
          if(res.code === 200){
            commit('setWorthValue', res.data[0])
          }
        })
      }
    },
    getBannerList({ commit, state }){
      if(!state.homeBanner || !state.otherBanner || !state.homeBusiness){
        getBannerList()
        .then(res => {
          if(res.code === 200){
            commit('setBanner', res.data)
          }
        })
      }
    },
  },
  modules: {}
});
