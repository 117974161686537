import request from '@/http/index';

/*荣誉信息*/
export const getHonorInfo = () => {
  return request({
      url:'website/showHonorInfo',
      method:"get",
  })
}

// 商务合作
export const getCoopInfo = () => {
  return request({
      url:'website/showCoopInfo',
      method:"get",
  })
}

// 公司福利
export const getWelfareInfo = () => {
  return request({
    url:'website/showWelfareInfo',
    method:"get",
  })
}

// 招聘类型 /
export const selectRecruitTypeList = (params) => {
  return request({
      url:'gateway/selectRecruitTypeList',
      method:"get",
      params
  })
}

// 招聘管理
export const getRecruitList = (params) => {
  return request({
      url:'gateway/getRecruitList',
      method:"get",
      params
  })
}

// 联系方式
export const getContactInfo = () => {
  return request({
      url:'website/showContactInfo',
      method:"get",
  })
}

// 企业价值观 
export const getWorthValue = () => {
  return request({
      url:'enterprise/getValue',
      method:"get",
  })
}