var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"nav"}},[_c('div',{staticClass:"wrap"},[_c('div',{staticClass:"center-align",attrs:{"id":"logo"},on:{"click":function($event){return _vm.toPath({ menuAddress: '/home' })}}},[_c('img',{attrs:{"src":require("../assets/logo网站.png"),"alt":"空间座标"}})]),(_vm.showMenuBtn)?_c('div',{staticClass:"showMenu",on:{"click":function($event){_vm.showDetailBtn = !_vm.showDetailBtn}}},[_c('em',{directives:[{name:"show",rawName:"v-show",value:(_vm.showDetailBtn),expression:"showDetailBtn"}],staticClass:"i_menu"}),_c('em',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showDetailBtn),expression:"!showDetailBtn"}],staticClass:"i_close"})]):_vm._e(),_c('div',{ref:"content",style:(_vm.contentStyle),attrs:{"id":"content"}},[_vm._l((_vm.nav),function(item,index){return [(item.path === '/solutions')?[_c('div',{key:index,ref:"jjfa",refInFor:true,staticClass:"nav_item",attrs:{"id":"jjfa"},on:{"mouseenter":_vm.mouseenter,"mouseleave":_vm.mouseleave}},[_c('div',{ref:"serve",refInFor:true,class:_vm.planSchemeVertical,style:({ width: _vm.showMenuBtn ? '100%' : '' }),on:{"click":function($event){$event.stopPropagation();return _vm.planSchemeClick.apply(null, arguments)}}},[_vm._v(" 解决方案与服务 "),_c('div',{ref:"line",refInFor:true,staticClass:"line-bottom",class:{ 'line-bottomAct': _vm.isPlanScheme },style:(_vm.isPlanScheme ? '' : _vm.lineStyle)})]),(_vm.planSchemeShow)?_c('el-cascader-panel',{ref:"Cascader",refInFor:true,staticClass:"text0",class:[_vm.myClass ? 'text' : 'text2', 'solution_menu'],attrs:{"options":_vm.solutionOpt,"id":"cascader","props":_vm.optProp},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var node = ref.node;
var data = ref.data;
return [_c('div',{ref:"casItem",refInFor:true,staticClass:"cas-item",on:{"mouseenter":function($event){return _vm.showList(node, $event, data)},"mouseleave":function($event){return _vm.hidList($event)},"click":function($event){return _vm.toPath(data, $event)}}},[_c('div',{staticClass:"cascader-item-line"}),_c('div',{staticClass:"classTwo-icon"},[_c('img',{attrs:{"src":require("../assets/classTwoIcon.png"),"alt":""}})]),_c('div',{staticClass:"classTwo-icon2"},[_c('img',{attrs:{"src":require("../assets/classTwoIcon2.png"),"alt":""}})]),_c('div',{staticClass:"classThree-icon"},[_c('img',{staticClass:"CTicon",attrs:{"src":_vm.classThreeIcon,"alt":""}})]),(
                      (data.disabled &&
                        data.menuName &&
                        data.menuName.length > 10) ||
                      (data.level === 4 && data.length > 18)
                    )?_c('el-tooltip',{staticClass:"item",attrs:{"placement":"top","effect":"dark","content":data.menuName}},[_c('div',{staticClass:"cas-item-text"},[_vm._v(_vm._s(data.menuName))])]):_c('div',{staticClass:"cas-item-text"},[_vm._v(_vm._s(data.menuName))]),_c('img',{staticClass:"right-icon",attrs:{"src":require("../assets/向右箭头.png"),"alt":""}})],1)]}}],null,true)}):_vm._e(),(_vm.planSchemeVerticalShow)?_c('el-menu',{key:("menu_" + (item.path)),staticClass:"el-menu-vertical-demo",attrs:{"default-active":"2","collapse":"","unique-opened":"","active-text-color":"#409EFF"}},[_vm._l((_vm.solutionOpt),function(item1){return [(item1.menuAddress)?_c('el-menu-item',{key:item1.id,attrs:{"index":item1.id}},[_c('div',{attrs:{"slot":"title"},on:{"click":function($event){return _vm.toPath(item1)}},slot:"title"},[_vm._v(" "+_vm._s(item1.menuName)+" ")])]):_c('el-submenu',{key:item1.id,attrs:{"index":item1.id}},[_c('template',{slot:"title"},[_vm._v(_vm._s(item1.menuName))]),_vm._l((item1.children),function(item2){return [(item2.menuAddress)?_c('el-menu-item',{key:item2.id,attrs:{"index":item2.id}},[_c('div',{attrs:{"slot":"title"},on:{"click":function($event){return _vm.toPath(item2)}},slot:"title"},[_vm._v(" "+_vm._s(item2.menuName)+" ")])]):_c('el-submenu',{key:item2.id,attrs:{"index":item2.id}},[_c('template',{slot:"title"},[_vm._v(_vm._s(item2.menuName))]),_vm._l((item2.children),function(item3){return [(item3.menuAddress)?_c('el-menu-item',{key:item3.menuName,attrs:{"index":item3.menuName}},[_c('div',{attrs:{"slot":"title"},on:{"click":function($event){return _vm.toPath(item3)}},slot:"title"},[_vm._v(" "+_vm._s(item3.menuName)+" ")])]):_vm._e()]})],2)]})],2)]})],2):_vm._e()],1)]:_c('div',{key:index,staticClass:"nav_item"},[_c('div',{staticClass:"abcenter"},[_c('router-link',{class:['router-link'],attrs:{"to":item.path ? item.path : '/404'}},[_vm._v(_vm._s(item.name)+" ")]),_c('div',{class:[
                _vm.$route.path === item.path ? 'line-bottomAct' : 'line-bottom' ]})],1)])]})],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }