import request from '@/http/index';
// 获取基础信息
export function getBaseInfo(params) {
  return request({
    url: 'website/showBaseInfo',
    method: 'get',
    params
  });
}

/*新闻列表*/ 
export const getNewsList = () => {
  return request({
      url:'gateway/getNewsList',
      method:"get",
  })
}

// 新闻列表
export const getNewsInfoList = (params) => {
  return request({
      url:'gateway/getNewsInfoList',
      method:"get",
      params
  })
}

// 新闻类型列表
export const getNewsTypeList = () => {
  return request({
      url:'gateway/getNewsTypeList',
      method:"get",
  })
}

// 友情链接
export const getLinksList = () => {
  return request({
      url:'gateway/getLinksList',
      method:"get",
  })
}

// 首页解决方案
export const getBannerList = () => {
  return request({
      url:'page/getPageConfigList',
      method:"get",
  })
}

// 获取新闻富文本信息 
export const getRichTextById = (params) => {
  return request({
      url:'gateway/getRichTextById',
      method:"get",
      params
  })
}