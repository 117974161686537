import axios from 'axios';
// import store from "../store"

const instance = axios.create({
  // baseURL: 'http://192.168.1.235:8888/portalSite',
  // baseURL: 'http://192.168.0.147:8888/portalSite/',
  baseURL: window.baseUrl,
  // baseURL: '/dataManagement',
  // baseURL: '/formal',
  timeout: 9000
});
instance.interceptors.request.use(
  function (config) {
    let token = sessionStorage.getItem('token');
    // console.log("判断请求头token", config.headers)
    // 在发送请求之前做些什么
    if (token) {
      //存在加上请求头token
      config.headers.token = token;
    }
    config.crossDomain = true;
    config.async = true;
    return config;
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);
instance.interceptors.response.use(
  response => {
    if (response.data.code == 401) {
      window.location.href = `${response.data.data}`;
    }
    return response.data;
  },
  error => {
    if (error.message.includes('timeout')) {
      // 判断请求异常信息中是否含有超时timeout字符串
      return Promise.reject(error); // reject这个错误信息
    } else if (error.message == 'Network Error') {
      return Promise.reject(error);
    } else {
      return Promise.reject(error); // reject这个错误信息
    }
  }
);

export default instance;
