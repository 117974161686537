<template>
  <div id="bottom-side">
    <div>
      <div>
        <el-collapse class="link-box" v-model="activeNames">
          <el-collapse-item
            v-for="(item, index) in linkMes"
            :key="item.title"
            :title="item.title"
            :name="index"
          >
            <div class="link-list" v-for="link in item.list" :key="link.title">
              <div
                v-if="!item.businessAddress"
                class="cancelStyle"
                @click="toPage(link.path, link.Id, link.dom)"
                >{{ link.title }}</div
              >
              <router-link
                :to="link.businessAddress || ''"
                class="cancelStyle"
                >{{ link.menuName }}</router-link
              >
            </div>
          </el-collapse-item>
        </el-collapse>
        <div class="part-box">
          <div class="part">
            <div class="title">关于我们</div>
            <div
              ><div class="cancelStyle" @click="toPage('About', 'Profile')"
                >公司简介</div
              ></div
            >
            <div>
              <div class="cancelStyle" @click="toPage('About', 'CoreValues')"
                >核心价值观</div
              >
            </div>
            <div>
              <div class="cancelStyle" @click="toPage('About', 'ryzz')"
                >企业荣誉资质</div
              >
            </div>
          </div>
          <div class="part">
            <div class="title">新闻中心</div>
            <div>
              <div
                class="cancelStyle"
                @click="toPage('News', 'gsNews', 'switch')"
                >公司新闻</div
              >
            </div>
            <div>
              <div
                class="cancelStyle"
                @click="toPage('News', 'hyNews', 'switch')"
                >行业新闻</div
              >
            </div>
          </div>
          <div class="part">
            <div class="title">解决方案与服务</div>
            <div v-for="item in linkMes[2].list" :key="item.id"
              ><router-link
                :to="item.businessAddress || ''"
                class="cancelStyle"
                >{{ item.menuName }}</router-link
              ></div
            >
          </div>
          <div class="part">
            <div class="title">友情链接</div>
            <div v-for="item in linkList" :key="item.id"
              ><a :href="item.website" target="blank" class="cancelStyle">{{
                item.linksName
              }}</a></div
            >
          </div>
        </div>
        <div id="part2">
          <div id="qgrx">全国服务热线</div>
          <div id="phoneNum">{{ contactInfo.telephone }}</div>
          <div class="input-box">
            <el-input
              v-model="idea"
              placeholder="请输入您的宝贵意见"
              class="opinion-input"
              maxlength="200"
            ></el-input>
          </div>
          <div class="input-box" style="margin-top: 5px">
            <el-input
              v-model="contactType"
              placeholder="请输入您邮箱或手机号"
              class="opinion-input"
              maxlength="200"
            ></el-input>
          </div>
          <div class="opinion-button-father">
            <div class="opinion-button" @click="submit">点击提交</div>
          </div>
          <div class="gzh-bottom-wrap">
            <div id="gzh-bottom" ref="erWeiMa">
              <img
                :src="$store.state.imgUrlConfig + contactInfo.wechat"
                alt=""
                class="gzh"
              />
            </div>
            <div
              id="weiXin-icon"
              @mouseenter="showErWeiMa($event)"
              @mouseleave="hideErWeiMa($event)"
            >
              <img :src="W1" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="bottom-line"></div>
      <div id="copyright" @click="beiAnClick" style="cursor:pointer">
        <span>
          四川空间座标信息科技有限公司 版权所有
          <img src="@/assets/bottom-icon.png" alt=" " />
          蜀ICP备13024118号-1
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import weixin1 from '../assets/微信.png';
import weixin2 from '../assets/微信2.png';
import { save } from '@/api/common';
export default {
  name: 'BottomSidebar',
  data() {
    return {
      idea: '', // 意见
      contactType: '', // 联系方式
      activeNames: '',
      inputValue: '',
      W1: weixin1,
      scrollTop: 0,
      linkMes: [
        {
          title: '关于我们',
          list: [
            {
              link: '',
              title: '公司简介',
              path: 'About',
              Id: 'Profile',
              dom: ''
            },
            {
              link: '',
              title: '核心价值观',
              path: 'About',
              Id: 'CoreValues',
              dom: ''
            },
            {
              link: '',
              title: '企业荣誉资质',
              path: 'About',
              Id: 'ryzz',
              dom: ''
            }
          ]
        },
        {
          title: '新闻中心',
          list: [
            {
              link: '',
              title: '公司新闻',
              path: 'News',
              Id: 'gsNews',
              dom: ''
            },
            {
              link: '',
              title: '行业新闻',
              path: 'News',
              Id: 'hyNews',
              dom: ''
            }
          ]
        },
        {
          title: '解决方案与服务',
          list: []
        }
      ]
    };
  },
  computed: {
    ...mapGetters(['linkList', 'contactInfo', 'homeBusiness'])
  },
  watch: {
    homeBusiness(val) {
      this.linkMes[2].list = val;
    }
  },
  mounted() {
    this.getBannerList();
    window.addEventListener('scroll', this.handleScroll);
    this.getLinksList();
    this.getContactInfo();
    if(this.homeBusiness.length > 0) this.linkMes[2].list = this.homeBusiness
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    ...mapActions(['getBannerList']),
    // 意见提交
    async submit() {
      if (!this.idea) {
        this.$message.warning('请输入您的宝贵意见');
        return;
      }
      if (!this.contactType) {
        this.$message.warning('请输入您邮箱或手机号');
        return;
      }
      let emailReg =
        /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/;
      let phoneReg =
        /^(0|86|17951)?(13[0-9]|15[0123456789]|17[678]|18[0-9]|14[57])[0-9]{8}$/;
      if (
        !emailReg.test(this.contactType) &&
        !phoneReg.test(this.contactType)
      ) {
        this.$message.warning('请输入正确的邮箱或手机号');
        return;
      }
      let { code } = await save({
        connect: this.contactType,
        content: this.idea
      });
      if (code !== 200) {
        console.log('提交失败');
        return;
      }
      this.$message.success('提交成功，感谢您的宝贵意见！');
    },
    ...mapActions(['getLinksList', 'getContactInfo']),
    toPage(path, Id, dom) {
      let routerPath = this.$route.path.split('/')[1];
      console.log(routerPath);
      if (routerPath == path) {
        if (dom) {
          let dom2 = document.getElementById(dom);
          if (dom2) {
            let length = this.getTop(dom2);
            this.toTop(length);
          }
        } else {
          let dom3 = document.getElementById(Id);
          if (dom3) {
            let length2 = this.getTop(dom3);
            this.toTop(length2);
          }
        }
        this.$router.push({ path: path, query: { Id: Id } });
      } else {
        this.$router.push({ path: path, query: { Id: Id } });
      }
    },
    beiAnClick() {
      window.open('https://beian.miit.gov.cn/', '_blank');
    },
    toTop(length) {
      const timeTop = setInterval(() => {
        document.body.scrollTop =
          document.documentElement.scrollTop =
          this.scrollTop -=
            50;
        if (this.scrollTop <= length - 80) {
          clearInterval(timeTop);
        }
      }, 5);
    },
    getTop(e) {
      var offset = e.offsetTop;
      if (e.offsetParent != null) offset += this.getTop(e.offsetParent);
      return offset;
    },
    handleScroll() {
      this.scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
    },
    showErWeiMa: function (event) {
      this.W1 = weixin2;
      this.$refs.erWeiMa.style.visibility = 'visible';
    },
    hideErWeiMa: function (event) {
      this.$refs.erWeiMa.style.visibility = 'hidden';
      this.W1 = weixin1;
    },
    linkId: function (id) {
      window.location.hash = id;
      console.log(id);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/styles/convert-viewport';
// #weiXin-icon:hover{
//   opacity: 0;
// }
.gzh-bottom-wrap{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
@media only screen and (min-width: $limitWidth) {
  #bottom-side {
    width: 100%;
    // height: vw(417);
    background: #262626;
  }
  #bottom-side > div {
    width: vw(1464);
    margin: 0 auto;
  }
  #bottom-side > div > div:first-child {
    width: vw(1464);
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
  }
  .link-box {
    display: none;
  }
  .title {
    height: vw(32);
    font-size: vw(24);
    font-family: MicrosoftYaHeiUISemibold, MicrosoftYaHeiUISemibold-Regular;
    font-weight: 400;
    text-align: left;
    color: #ffffff;
    line-height: 32px;
    margin-bottom: vw(24);
  }
  .part {
    margin-right: vw(110);
    margin-top: vw(48);
  }
  .part > div:not(:first-child) {
    height: vw(27);
    opacity: 0.7;
    font-size: vw(20);
    font-family: MicrosoftYaHeiUI, MicrosoftYaHeiUI-Regular;
    font-weight: 400;
    text-align: left;
    color: #ffffff;
    line-height: 27px;
    margin-bottom: vw(20);
  }
  .part > div:not(:first-child):hover {
    opacity: 1;
  }
  #part2 {
    margin-top: vw(48);
    position: relative;
  }
  .title {
    height: vw(32);
    font-size: vw(24);
    font-family: MicrosoftYaHeiUISemibold, MicrosoftYaHeiUISemibold-Regular;
    font-weight: 400;
    text-align: left;
    color: #ffffff;
    line-height: 32px;
    margin-bottom: vw(24);
  }
  .gzh {
    width: vw(100);
    height: vw(100);
  }
  #qgrx {
    // height: vw(32);
    font-size: vw(24);
    font-family: MicrosoftYaHeiUI, MicrosoftYaHeiUI-Regular;
    font-weight: 400;
    text-align: left;
    color: #ffffff;
    line-height: 32px;
    letter-spacing: 3.3px;
    text-align: right;
    margin-bottom: 4px;
    cursor: default;
  }
  #phoneNum {
    height: vw(31);
    font-size: vw(24);
    font-family: DINPro, DINPro-Bold;
    font-weight: 700;
    text-align: left;
    color: #ffffff;
    line-height: 31px;
    text-align: right;
    margin-bottom: 4px;
    cursor: default;
  }
  .opinion-input {
    width: vw(314);
    // height: vw(36);
    background: #ffffff;
    border-radius: 2px;
  }
  .opinion-button-father {
    width: vw(314);
    display: flex;
    justify-content: flex-end;
    margin-top: 6px;
    margin-bottom: 6px;
    cursor: pointer;
  }
  .opinion-button {
    width: vw(80);
    height: vw(27);
    background: #ff6c2f;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 14px;
    font-family: MicrosoftYaHeiUI, MicrosoftYaHeiUI-Regular;
    font-weight: 400;
    color: #ffffff;
    line-height: 19px;
  }
  #gzh-bottom {
    text-align: left;
    visibility: hidden;
  }
  #gzh-bottom > img {
    // margin-left: 48px;
  }
  .bottom-line {
    width: vw(1464);
    height: 1px;
    border: 1px solid rgba(255, 255, 255, 0.15);
    // margin: 0 auto;
    // margin-top: vw(70);
    // margin-bottom: vw(20.5);
  }
  #copyright {
    // width: vw(434);
    height: vw(30);
    opacity: 0.7;
    font-size: 14px;
    font-family: MicrosoftYaHeiUI, MicrosoftYaHeiUI-Regular;
    font-weight: 400;
    text-align: center;
    color: #ffffff;
    line-height: 19px;
    margin: 0 auto;
    // margin-bottom: vw(17);
  }
  #weiXin-icon {
    // position: absolute;
    // left: 40px;
    text-align: left;
    margin-left: vw(80);
    top: vw(270);
    cursor: pointer;
  }
  .cancelStyle {
    color: #ffffff;
    text-decoration: none;
    cursor: pointer;
  }
}
@media only screen and (min-width: 600px) and (max-width: $limitWidth) {
  #bottom-side {
    min-width: vwlimit(1464);
    // height: vwlimit(417);
    background: #262626;
  }
  #bottom-side > div {
    width: vwlimit(1464);
    margin: 0 auto;
  }
  #bottom-side > div > div:first-child {
    width: vwlimit(1464);
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
  }
  .link-box {
    display: none;
  }
  .title {
    height: vwlimit(32);
    font-size: vwlimit(24);
    font-family: MicrosoftYaHeiUISemibold, MicrosoftYaHeiUISemibold-Regular;
    font-weight: 400;
    text-align: left;
    color: #ffffff;
    line-height: 32px;
    margin-bottom: vwlimit(24);
  }
  .part {
    margin-right: vwlimit(110);
    margin-top: vwlimit(48);
  }
  .part > div:not(:first-child) {
    height: vwlimit(27);
    opacity: 0.7;
    font-size: vwlimit(20);
    font-family: MicrosoftYaHeiUI, MicrosoftYaHeiUI-Regular;
    font-weight: 400;
    text-align: left;
    color: #ffffff;
    line-height: 27px;
    margin-bottom: vwlimit(20);
  }
  .part > div:not(:first-child):hover {
    opacity: 1;
  }
  #part2 {
    margin-top: vwlimit(48);
    position: relative;
  }
  .title {
    height: vwlimit(32);
    font-size: vwlimit(24);
    font-family: MicrosoftYaHeiUISemibold, MicrosoftYaHeiUISemibold-Regular;
    font-weight: 400;
    text-align: left;
    color: #ffffff;
    line-height: 32px;
    margin-bottom: vwlimit(24);
  }
  .gzh {
    width: vwlimit(100);
    height: vwlimit(100);
  }
  #qgrx {
    // height: vwlimit(32);
    font-size: vwlimit(24);
    font-family: MicrosoftYaHeiUI, MicrosoftYaHeiUI-Regular;
    font-weight: 400;
    text-align: left;
    color: #ffffff;
    line-height: 32px;
    letter-spacing: 3.3px;
    text-align: right;
    margin-bottom: 4px;
    cursor: default;
  }
  #phoneNum {
    height: vwlimit(31);
    font-size: vwlimit(24);
    font-family: DINPro, DINPro-Bold;
    font-weight: 700;
    text-align: left;
    color: #ffffff;
    line-height: 31px;
    text-align: right;
    margin-bottom: 4px;
    cursor: default;
  }
  .opinion-input {
    width: vwlimit(314);
    // height: vwlimit(36);
    background: #ffffff;
    border-radius: 2px;
  }
  .opinion-button-father {
    width: vwlimit(314);
    display: flex;
    justify-content: flex-end;
    margin-top: 6px;
    margin-bottom: 6px;
    cursor: pointer;
  }
  .opinion-button {
    width: vwlimit(80);
    height: vwlimit(27);
    background: #ff6c2f;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 14px;
    font-family: MicrosoftYaHeiUI, MicrosoftYaHeiUI-Regular;
    font-weight: 400;
    color: #ffffff;
    line-height: 19px;
  }
  #gzh-bottom {
    text-align: left;
    visibility: hidden;
  }
  #gzh-bottom > img {
    // margin-left: 48px;
  }
  .bottom-line {
    width: vwlimit(1464);
    height: 1px;
    border: 1px solid rgba(255, 255, 255, 0.15);
    // margin: 0 auto;
    // margin-top: vwlimit(70);
    // margin-bottom: vwlimit(20.5);
  }
  #copyright {
    // width: vwlimit(434);
    height: vwlimit(30);
    opacity: 0.7;
    font-size: 14px;
    font-family: MicrosoftYaHeiUI, MicrosoftYaHeiUI-Regular;
    font-weight: 400;
    text-align: center;
    color: #ffffff;
    line-height: 19px;
    margin: 0 auto;
    // margin-bottom: vwlimit(17);
  }
  #weiXin-icon {
    // position: absolute;
    // left: 40px;
    text-align: left;
    margin-left: vwlimit(80);
    top: vwlimit(270);
    cursor: pointer;
  }
  .cancelStyle {
    color: #ffffff;
    text-decoration: none;
    cursor: pointer;
  }
}
@media only screen and (min-width: 0px) and (max-width: 600px) {
  #bottom-side {
    min-width: 100%;
    // height: vwlimit(417);
    background: #262626;
  }
  #bottom-side > div {
    width: 100%;
    margin: 0 auto;
  }
  #bottom-side > div > div:first-child {
    width: 100%;
    // display: flex;
    justify-content: space-between;
    margin: 0 auto;
  }
  .part-box {
    display: none;
  }
  .title {
    height: vwlimit(32);
    font-size: vwlimit(24);
    font-family: MicrosoftYaHeiUISemibold, MicrosoftYaHeiUISemibold-Regular;
    font-weight: 400;
    text-align: left;
    color: #ffffff;
    line-height: 32px;
    margin-bottom: vwlimit(24);
  }
  .part {
    margin-right: vwlimit(110);
    margin-top: vwlimit(48);
  }
  .part > div:not(:first-child) {
    height: vwlimit(27);
    opacity: 0.7;
    font-size: vwlimit(20);
    font-family: MicrosoftYaHeiUI, MicrosoftYaHeiUI-Regular;
    font-weight: 400;
    text-align: left;
    color: #ffffff;
    line-height: 27px;
    margin-bottom: vwlimit(20);
  }
  .part > div:not(:first-child):hover {
    opacity: 1;
  }
  #part2 {
    margin-top: vwlimit(48);
    position: relative;
  }
  .title {
    height: vwlimit(32);
    font-size: vwlimit(24);
    font-family: MicrosoftYaHeiUISemibold, MicrosoftYaHeiUISemibold-Regular;
    font-weight: 400;
    text-align: left;
    color: #ffffff;
    line-height: 32px;
    margin-bottom: vwlimit(24);
  }
  .gzh {
    width: vwlimit(100);
    height: vwlimit(100);
  }
  #qgrx {
    width: 100%;
    // height: vwlimit(32);
    font-size: vwlimit(24);
    font-family: MicrosoftYaHeiUI, MicrosoftYaHeiUI-Regular;
    font-weight: 400;
    text-align: left;
    color: #ffffff;
    line-height: 32px;
    letter-spacing: 3.3px;
    text-align: center;
    margin-bottom: 4px;
    cursor: default;
  }
  #phoneNum {
    width: 100%;
    // height: vwlimit(31);
    font-size: vwlimit(24);
    font-family: DINPro, DINPro-Bold;
    font-weight: 700;
    color: #ffffff;
    line-height: 31px;
    text-align: center;
    margin-bottom: 4px;
    cursor: default;
  }
  .opinion-input {
    width: vwlimit(314);
    // height: vwlimit(36);
    background: #ffffff;
    border-radius: 2px;
  }
  .opinion-button-father {
    // width: vwlimit(314);
    display: flex;
    justify-content: center;
    margin-top: 6px;
    margin-bottom: 6px;
    cursor: pointer;
  }
  .opinion-button {
    width: vwlimit(80);
    height: vwlimit(27);
    background: #ff6c2f;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 14px;
    font-family: MicrosoftYaHeiUI, MicrosoftYaHeiUI-Regular;
    font-weight: 400;
    color: #ffffff;
    line-height: 19px;
  }
  .gzh-bottom-wrap{
    justify-content: center;
  }
  #gzh-bottom {
    text-align: center;
    visibility: visible;
  }
  // #gzh-bottom>img{
  //   margin-left: 48px;
  // }
  .bottom-line {
    width: 100%;
    height: 1px;
    border: 1px solid rgba(255, 255, 255, 0.15);
    // margin: 0 auto;
    // margin-top: vwlimit(70);
    // margin-bottom: vwlimit(20.5);
  }
  #copyright {
    width: 100%;
    height: vwlimit(30);
    opacity: 0.7;
    font-size: 14px;
    font-family: MicrosoftYaHeiUI, MicrosoftYaHeiUI-Regular;
    font-weight: 400;
    text-align: left;
    color: #ffffff;
    line-height: 19px;
    margin: 0 auto;
    // margin-bottom: vwlimit(17);
  }
  #weiXin-icon {
    display: none;
    // position: absolute;
    // left: 0;
    text-align: left;
    margin-left: vwlimit(80);
    top: vwlimit(250);
    cursor: pointer;
  }
  .cancelStyle {
    color: #ffffff;
    text-decoration: none;
    cursor: pointer;
  }
  .part {
    display: none;
  }
  #part2 {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .input-box {
    display: flex;
    justify-content: center;
  }
}
</style>
<style lang="scss" scoped>
#bottom-side::v-deep {
  .el-collapse {
    padding: 0 15px;
    border: none;
  }
  .el-collapse-item__header {
    background-color: #262626;
    color: #fff;
    border: none;
    font-size: 14px;
  }
  .el-collapse-item__content {
    background-color: #262626;
    border: none;
  }
  .el-collapse-item__wrap {
    border: none;
  }
  .el-collapse-item__arrow {
    display: block;
    font-size: 12px;
    color: #fff;
  }
  .el-icon-arrow-right:before {
    font-size: 14px;
  }
  text-align: left;
}
.part-box {
  display: flex;
}
</style>
