<template>
  <div id="nav">
    <div class="wrap">
      <div
        id="logo"
        class="center-align"
        @click="toPath({ menuAddress: '/home' })"
      >
        <img src="../assets/logo网站.png" alt="空间座标" />
      </div>
      <!-- 移动端图标显示 -->
      <div
        class="showMenu"
        v-if="showMenuBtn"
        @click="showDetailBtn = !showDetailBtn"
      >
        <em class="i_menu" v-show="showDetailBtn"> </em>
        <em class="i_close" v-show="!showDetailBtn"> </em>
      </div>
      <div id="content" ref="content" :style="contentStyle">
        <template v-for="(item, index) in nav">
          <template v-if="item.path === '/solutions'">
            <div
              :key="index"
              id="jjfa"
              class="nav_item"
              @mouseenter="mouseenter"
              @mouseleave="mouseleave"
              ref="jjfa"
            >
              <div
                @click.stop="planSchemeClick"
                :style="{ width: showMenuBtn ? '100%' : '' }"
                ref="serve"
                :class="planSchemeVertical"
              >
                解决方案与服务
                <div
                  class="line-bottom"
                  ref="line"
                  :style="isPlanScheme ? '' : lineStyle"
                  :class="{ 'line-bottomAct': isPlanScheme }"
                ></div>
              </div>
              <!-- 解决方案弹窗 -->
              <el-cascader-panel
                :options="solutionOpt"
                v-if="planSchemeShow"
                id="cascader"
                ref="Cascader"
                class="text0"
                :props="optProp"
                :class="[myClass ? 'text' : 'text2', 'solution_menu']"
              >
                <template #default="{ node, data }">
                  <div
                    class="cas-item"
                    @mouseenter="showList(node, $event, data)"
                    @mouseleave="hidList($event)"
                    ref="casItem"
                    @click="toPath(data, $event)"
                  >
                    <div class="cascader-item-line"></div>
                    <div class="classTwo-icon">
                      <img src="../assets/classTwoIcon.png" alt="" />
                    </div>
                    <div class="classTwo-icon2">
                      <img src="../assets/classTwoIcon2.png" alt="" />
                    </div>
                    <div class="classThree-icon">
                      <img :src="classThreeIcon" class="CTicon" alt="" />
                    </div>
                    <el-tooltip
                      v-if="
                        (data.disabled &&
                          data.menuName &&
                          data.menuName.length > 10) ||
                        (data.level === 4 && data.length > 18)
                      "
                      class="item"
                      placement="top"
                      effect="dark"
                      :content="data.menuName"
                    >
                      <div class="cas-item-text">{{ data.menuName }}</div>
                    </el-tooltip>
                    <div v-else class="cas-item-text">{{ data.menuName }}</div>
                    <img
                      src="../assets/向右箭头.png"
                      alt=""
                      class="right-icon"
                    />
                  </div>
                </template>
              </el-cascader-panel>
              <el-menu
                v-if="planSchemeVerticalShow"
                :key="`menu_${item.path}`"
                default-active="2"
                collapse
                unique-opened
                active-text-color="#409EFF"
                class="el-menu-vertical-demo"
              >
                <!-- 一级菜单 -->
                <template v-for="item1 in solutionOpt">
                  <el-menu-item
                    :key="item1.id"
                    :index="item1.id"
                    v-if="item1.menuAddress"
                  >
                    <div @click="toPath(item1)" slot="title">
                      {{ item1.menuName }}
                    </div>
                  </el-menu-item>
                  <!-- 二级菜单 -->
                  <el-submenu v-else :index="item1.id" :key="item1.id">
                    <template slot="title">{{ item1.menuName }}</template>
                    <template v-for="item2 in item1.children">
                      <el-menu-item
                        v-if="item2.menuAddress"
                        :key="item2.id"
                        :index="item2.id"
                      >
                        <div @click="toPath(item2)" slot="title">
                          {{ item2.menuName }}
                        </div>
                      </el-menu-item>
                      <!-- 三级菜单 -->
                      <el-submenu v-else :index="item2.id" :key="item2.id">
                        <template slot="title">{{ item2.menuName }}</template>
                        <template v-for="item3 in item2.children">
                          <!-- 排除禁用菜单 -->
                          <el-menu-item
                            v-if="item3.menuAddress"
                            :key="item3.menuName"
                            :index="item3.menuName"
                          >
                            <div @click="toPath(item3)" slot="title">
                              {{ item3.menuName }}
                            </div>
                          </el-menu-item>
                        </template>
                      </el-submenu>
                    </template>
                  </el-submenu>
                </template>
              </el-menu>
            </div>
          </template>
          <div v-else :key="index" class="nav_item">
            <div class="abcenter">
              <router-link
                :to="item.path ? item.path : '/404'"
                :class="['router-link']"
                >{{ item.name }}
              </router-link>
              <div
                :class="[
                  $route.path === item.path ? 'line-bottomAct' : 'line-bottom',
                ]"
              ></div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import class3Icon1 from "../assets/class3-icon2.png";
import class3Icon2 from "../assets/class3-icon1.png";
import { getTree } from "@/api/common";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "navigation",
  data() {
    return {
      classThreeIcon: class3Icon1,
      classThreeIcon2: class3Icon2,
      myClass: false,
      myClass2: false,
      optProp: {
        expandTrigger: "hover",
        label: "menuName",
        value: "menuName",
        disabled: "disabled",
      }, // 解决方案菜单选项
      nav: [
        {
          name: "首页",
          path: "/home",
        },
        {
          name: "解决方案与服务",
          path: "/solutions",
        },
        {
          name: "新闻中心",
          path: "/news",
        },
        {
          name: "职等你来",
          path: "/Position",
        },
        {
          name: "关于我们",
          path: "/About",
        },
      ], // 菜单导航
      cascaderId: "cascader",
      lineStyle: {},
      planSchemeShow: false, // 是否显示解决方案
      showMenuBtn: false, //是否显示菜单按钮（移动端显示）
      showDetailBtn: true, // 是否显示菜单详情图标
      planSchemeVerticalShow: false, // 是否显示解决方案详情
      solutionOpt: [], // 解决方案菜单选项
      nav3_title: "", //3级标题
      fristNodeId: "", // 3级下级第一个nodeId
    };
  },
  computed: {
    // 当前页面是否属于解决方案模块下
    isPlanScheme() {
      if (this.$route.path === "/solution" || this.$route.path === "/cases")
        return true;
      return false;
    },
    // 计算contentStyle（移动端）
    contentStyle() {
      if (this.showMenuBtn) {
        return {
          transform: `translate(${!this.showDetailBtn ? "0" : "-260px"}, 0)`,
          opacity: `${!this.showDetailBtn ? 1 : 0.8}`,
        };
      }
      return {};
    },
    planSchemeVertical() {
      let css = [this.isPlanScheme ? "router-link-active" : "", "router-link"];
      if (!this.showMenuBtn) {
        return css;
      }
      if (this.planSchemeVerticalShow) {
        css.push("verticalPlan2");
      } else {
        css.push("verticalPlan");
      }
      return css;
    },
  },
  methods: {
    async getTree() {
      let { data, code } = await getTree();
      if (code !== 200) {
        this.$message.error("菜单获取失败");
        return;
      }
      // let solution = data?.filter(v => v.menuName == '解决方案') || [];
      // let solutionOpt = solution.length > 0 ? solution[0]?.children : [];
      let solutionOpt = data;
      this.solutionOpt = this.nodeHandler(solutionOpt);
    },
    nodeHandler(data) {
      return data?.map((v) => {
        if (v.menuAddress) {
          Reflect.deleteProperty(v, "children");
        } else {
          // 业务逻辑列表增加层级
          let businessList =
            v?.subpageVOS?.map((p) => {
              return { ...p, level: (v?.level || 0) + 1 };
            }) || [];
          v.children = [...(v?.children || []), ...businessList];
          if (v.children[0]?.level === 4) {
            v.children.unshift({ menuName: v.menuName, disabled: true });
          }
          this.nodeHandler(v.children);
        }
        return v;
      });
    },
    toPath: function (data) {
      let path = data?.menuAddress;
      if (!path) {
        console.log("未配置菜单地址", data);
        return;
      }
      this.planSchemeShow = false;
      if (path !== this.$route.fullPath) this.$router.push({ path: path });
    },
    // 点击解决方案(移动端)
    planSchemeClick() {
      if (this.showMenuBtn) {
        this.planSchemeVerticalShow = !this.planSchemeVerticalShow;
        return;
      }
    },
    mouseenter() {
      if (this.showMenuBtn) return;
      this.planSchemeShow = true;
    },
    mouseleave() {
      if (this.showMenuBtn) return;
      this.planSchemeShow = false;
    },
    mouseenter2: function (event) {
      event.stopPropagation();
    },
    showList: function (node, event, data) {
      // if (data.level === 3 && !!data.children?.length) {
      //   this.fristNodeId = node.children[0]?.data?.id;
      //   this.nav3_title = node?.data?.menuName;
      // }
      /// level === 2  一级列表（基础，测绘）
      if (data.children && data.children.length > 0) {
        event.currentTarget.querySelector(".right-icon").style.display =
          "inline";
      }
      if (data.level == 2 || data.level == 3) {
        event.currentTarget.querySelector(".cas-item-text").style.opacity = "1";
      }
      // event.target.style.backgroundColor= 'rgba(88,157,255,0.42)'
      if (data.level == 2) {
        event.currentTarget.querySelector(
          ".cascader-item-line"
        ).style.visibility = "visible";
      }
      if (data.level == 2 && data.children) {
        // 循环查找children ，如果下一级有一个层级包含子级则返回true(类型黑色背景)
        this.myClass = data.children?.some((item) => item.children);
      }
      if (data.level === 3) {
        if (this.myClass) {
          event.currentTarget.querySelector(".classTwo-icon").style.visibility =
            "visible";
        } else {
          event.currentTarget.querySelector(
            ".classTwo-icon2"
          ).style.visibility = "visible";
        }
      }
      if (data.level === 4) {
        event.currentTarget.querySelector(".CTicon").src = class3Icon2;
      }
    },
    hidList: function (event) {
      event.currentTarget.querySelector(".right-icon").style.display = "none";
      event.currentTarget.querySelector(
        ".cascader-item-line"
      ).style.visibility = "hidden";
      event.currentTarget.querySelector(".cas-item-text").style.opacity = "0.7";
      event.currentTarget.querySelector(".classTwo-icon").style.visibility =
        "hidden";
      event.currentTarget.querySelector(".classTwo-icon2").style.visibility =
        "hidden";
      event.currentTarget.querySelector(".CTicon").src = class3Icon1;
    },
    setMenu(el) {
      if (el.matches) {
        this.showMenuBtn = true;
      } else {
        this.showMenuBtn = false;
      }
    },
    // 自动设置宽度（防止抖动）
    setNavWidth() {
      this.planSchemeVerticalShow = false;
      let navList = document.querySelectorAll(".nav_item");
      navList.forEach((el) => {
        if (window.matchMedia("(max-width: 800px)").matches) {
          el.style.width = "auto";
        } else {
          el.style.width = "auto";
          el.style.width = el.clientWidth * 1.2 + "px";
        }
        // 不是移动端（横向菜单）则动态设置宽度
      });
    },
  },
  created() {
    this.getTree();
  },
  mounted() {
    const mQuery = window.matchMedia(
      "(min-width: 800px) and (max-width: 1477px)"
    );
    // 媒体查询 800px （移动端）
    const sQuery = window.matchMedia("(max-width: 800px)");
    this.setNavWidth(mQuery);
    mQuery.addListener(this.setNavWidth);
    this.setMenu(sQuery);
    sQuery.addListener(this.setMenu);
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/convert-viewport";
::v-deep .el-menu {
  transition: none;
  border-right: none;
}
::v-deep .el-submenu__title {
  font-size: 10px;
  transition: none;
}
::v-deep li.el-submenu {
    height: 6vh;
}
::v-deep .el-submenu__icon-arrow {
  // display: block;
  // color: #1791ec;
  // right: 5px;
  // font-size: var(--ft14);
}
::v-deep .el-menu--collapse {
  width: auto;
}
::v-deep .text .el-cascader-menu:nth-child(3) {
  .cas-item-text {
    max-width: 320px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
::v-deep .text .el-cascader-menu:nth-child(3) .el-cascader-node:nth-child(1) {
  // height: 90px;
  .title {
    display: flex;
    margin-left: 48px;
    height: 37px;
    margin-bottom: 28px;
    font-weight: 400;
    color: #333333;
    line-height: 37px;
    opacity: 1 !important;
    font-size: var(--ft28);
  }
}
::v-deep
  .text
  .el-cascader-menu:nth-child(3)
  .el-cascader-node:first-child
  .cascader-item-line {
  margin-right: 30px;
}
::v-deep .el-cascader-node.in-active-path {
}
// .el-cascader-menu:nth-child(3) .cas-item {
//   padding: 0 40px 0 0px;
// }
.wrap {
  justify-content: space-between;
}
.center-align {
  margin: auto 0;
  cursor: pointer;
}
.center-align > img {
  width: 217px;
  height: 56px;
}

#nav {
  width: 100vw;
  height: 80px;
  background-color: white;
  display: flex;
  justify-content: center;
  z-index: 30;
  position: fixed;
  top: 0px;
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.17);
}
#logo {
  // margin-left: vw(227.81);
  // margin-right: vw(279.18);
}
#content {
  display: flex;
  justify-content: space-around;
}
// #content > div:first-child {
//   width: vw(60);
// }
// #content > div:nth-child(3) {
//   width: vw(120);
// }
// #content > div:nth-child(4) {
//   width: vw(120);
// }
// #content > div:nth-child(5) {
//   width: vw(120);
// }
.nav_item {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-left: 60px;
  transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
}
.router-link {
  text-decoration: none;
  color: black;
  font-size: 20px;
  line-height: 32px;
  cursor: pointer;
}
.router-link-active {
  font-size: 24px;
  color: #1791ec;
  line-height: 32px;
  // width: vw(180);
  font-weight: 400;
}

.router-link:hover {
  font-size: 24px;
  color: #1791ec;
  line-height: 32px;
  // width: vw(180);
  font-weight: 400;
}
.router-link:hover + .line-bottom {
  width: vw(48);
  transition: width 0.3s linear;
}
#jjfa {
  // position: relative;
  // width: vw(180);
}
.verticalPlan,
.verticalPlan2 {
  position: relative;
  ::after {
    content: "";
    position: absolute;
    width: 8px;
    height: 8px;
    top: 9px;
    right: 50px;
    border-bottom: #1791ec 1px solid;
    border-right: #1791ec 1px solid;
  }
}
.verticalPlan ::after {
  transform: rotate(45deg);
}
.verticalPlan2 ::after {
  top: 12px;
  transform: rotate(225deg);
}

#cascader {
  // visibility: hidden;
  position: absolute;
  left: 0;
  top: 80px;
  z-index: 10;
  background: #04153b;
  border-radius: 0px 0px 8px 8px;
  border: none;
  color: #ffffff;
}
.cascader-item-line {
  visibility: hidden;
  width: 6px;
  height: 100%;
  background: #ff6c2f;
  margin-right: 34px;
}
.text .el-cascader-menu:nth-child(3) .el-cascader-node .cascader-item-line {
  margin-right: 44px;
}
.cas-item-text {
  opacity: 0.7;
}

#cascader2 {
  visibility: visible;
  position: absolute;
  left: 0;
  top: 58px;
  z-index: 10;
  background: #04153b;
  border-radius: 0px 0px 8px 8px;
  border: none;
  color: #ffffff;
}
.line-bottom {
  width: 0px;
  height: 4px;
  background: #1791ec;
  border-radius: 3px;
  margin: 0 auto;
  transition: width 0.3s linear;
}
.line-bottomAct {
  width: 48px;
  height: 4px;
  background: #1791ec;
  border-radius: 3px;
  margin: 0 auto;
  transition: width 0.3s linear;
}
.classTwo-icon {
  position: absolute;
  left: 24px;
  top: 11px;
  visibility: hidden;
}
.classTwo-icon2 {
  position: absolute;
  left: 24px;
  top: 11px;
  visibility: hidden;
}
.classThree-icon {
  position: absolute;
  left: 35px;
  top: 0px;
  display: none;
}
.cas-item {
  padding: 0 40px 0 0px;
  width: 100%;
  height: 51px;
  text-decoration: none;
  font-weight: 400;

  /*line-height: 51px;*/
  display: flex;
  align-items: center;
  box-sizing: border-box;
  position: relative;
}
.cas-item:hover {
  /*background: rgba(88,157,255,0.42);*/
}
.cas-item2 {
  text-decoration: none;
  color: #ffffff;
}
.right-icon {
  display: none;
  position: absolute;
  right: 20px;
  bottom: 16px;
}
.background {
  background-color: red;
}
.clearfix::after {
  content: "";
  height: 0;
  line-height: 0; //行高为0
  display: block; //将文本转为块级元素
  visibility: hidden; //将元素隐藏
  clear: both//清除浮动;
}
.showMenu {
  z-index: 999;
  height: 20px;
  width: 20px;
  position: absolute;
  left: 21px;
  top: 18px;
}
::v-deep ul.el-menu-vertical-demo.sideMenu.el-menu {
  position: absolute;
  left: 0;
}
// 1477 = 元素宽 + 外边距 20 + 滚动条 6 ，考虑边界情况 再 - 1
@media only screen and (min-width: 800px) and (max-width: 1477px) {
  // #content {
  //   width: vwlimit(1000);
  // }
  .nav_item {
    margin-left: ms(60);
  }
  .router-link {
    font-size: 15px;
  }
  .router-link-active,
  .router-link:hover {
    font-size: 18px;
  }
  .router-link:hover + .line-bottom {
    width: vwlimit(48);
  }
}
@media screen and (min-width: 0px) and (max-width: 800px) {
  .wrap {
    justify-content: center;
  }
  .router-link {
    font-size: 12px;
  }
  .router-link-active,
  .router-link:hover {
    font-size: 15px;
  }
  .nav_item {
    width: auto !important;
    height: auto;
    margin: 5vh 0;
  }
  #content {
    flex-direction: column;
    position: fixed;
    background: #fff;
    width: 260px;
    height: 100vh;
    right: 0px;
    left: 0px;
    transform: translate(-260px, 0);
    opacity: 0.8;
    transition: transform 0.25s ease-out, opacity 0.25s ease-out;
    justify-content: flex-start;
  }
  .i_menu {
    display: inline-block;
    background: url("@/assets/icon_menu_hover.png") no-repeat center;
    background-size: 100% 100%;
    width: 20px;
    height: 20px;
    margin-top: 0;
  }
  .i_close {
    display: inline-block;
    height: 14px;
    width: 15px;
    background: url("@/assets/icon_menu_close.png") no-repeat 0 0;
    background-size: contain;
    position: fixed;
    left: 21px;
    top: 21px;
    z-index: 4;
  }
}
</style>
<style>
.el-icon-arrow-right:before {
  display: inline;
  font-size: var(--ft18);
  font-weight: normal;
}
.el-cascader-node__postfix {
  position: absolute;
  right: 40px;
  top: -2px;
}
#cascader-menu-2524-1 {
  background-color: red;
}

.text0 {
  border-radius: 0px 0px 0px 8px;
}

.solution_menu {
  border-radius: 0px 0px 0px 8px;
}

/* .el-cascader-menu{
  max-width: 200px
} */

.text0 .el-cascader-menu:nth-child(1) {
  background-color: #04153b;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.17);
  font-size: var(--ft20);
  border-radius: 0px 0px 8px 8px;
}

.text .el-cascader-menu:nth-child(2) {
  font-size: var(--ft20);
  background-color: #0e2863;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.17);
  border-radius: 0px 0px 8px 0px;
}

.text .el-cascader-menu:nth-child(3) {
  background-color: #ffffff;
  /* width: 320px; */
  font-size: var(--ft16) !important;
  font-family: MicrosoftYaHeiUI, MicrosoftYaHeiUI-Regular;
  font-weight: 400;
  color: #989898 !important;
  line-height: 21px;
  opacity: 1;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.17);
  border-radius: 0px 0px 8px 0px;
}

.text .el-cascader-menu:nth-child(3) .el-cascader-node {
  height: 21px;
  margin-bottom: 12px;
}

.text .el-cascader-menu:nth-child(3) .el-cascader-node:first-child {
  /* margin-left: -15px; */
  height: 37px;
  margin-bottom: 28px;
  font-size: var(--ft28);
  font-weight: 400;
  color: #333333;
  line-height: 37px;
  opacity: 1 !important;
}

.text
  .el-cascader-menu:nth-child(3)
  .el-cascader-node:not(:first-child)
  .classThree-icon {
  display: inline;
}

.text2 .el-cascader-menu:nth-child(2) {
  font-size: var(--ft20);
  background-color: #ffffff;
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.17);
  border-radius: 0px 0px 8px 0px;
  color: #666666 !important;
  /* width: 280px; */
}
</style>
