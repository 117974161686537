<template>
  <div>
    <navigation id="nav"></navigation>
      <router-view></router-view>
    <BottomSidebar></BottomSidebar>
  </div>

</template>

<script>
// import navigation from "@/components/nav";
import navigation from "@/components/navigation";
import BottomSidebar from "@/components/Bottom-sidebar"

export default {
  name: "LayoutNew",
  components:{
    navigation,
    BottomSidebar,
  },
  data(){
    return{
    }
  },
  methods:{
  }
}
</script>

<style lang="scss" scoped>
</style>