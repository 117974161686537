import request from '@/http/index';
// 提交合作意向内容
export function save(data) {
  return request({
    url: '/cooperation/save',
    method: 'post',
    data
  });
}

// 门户首页菜单树
export function getTree() {
  return request({
    url: '/gateway/getTree',
    method: 'get'
  });
}
